import React, { useState, useEffect, useContext } from 'react';
import SiteRequestTable from './components/SiteRequestTable';

export default ({children, user, currentOrg, isQueryPending, infinityLoad, onSetCurrentUser}) => {

  return (
    <>
      {children}
      <div id="site-requests" className="page main-page">
        <div className="header">
          <h2>Site Requests</h2>
        </div>
        <SiteRequestTable currentOrg={currentOrg} user={user} isQueryPending={isQueryPending} infinityLoad={infinityLoad} onSetCurrentUser={onSetCurrentUser} />
      </div>
    </>
  )
}


