import { BASE_URL, USERS, ME, API_VERSION, RESET_PASSWORD } from "../settings/apiUrls";
import axios from "axios";
import api from "./api";

export const getMe = () => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + USERS + ME,
    }).then((response) => {
        return response.data;
    });
}

// export const getUser = (userId) => {
//     return api({
//         method: 'get',
//         url: BASE_URL + API_VERSION + USERS + '/' + userId,
//     }).then((response) => {
//         return response.data;
//     });
// }

export const addUser = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + USERS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const updateUser = (data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + USERS + ME,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const removeMe = () => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + USERS + ME,
    }).then((response) => {
        return response;
    });
}

export const resetPassword = (data) => {
    return axios.post(BASE_URL + API_VERSION + RESET_PASSWORD, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
      .then(function (response) {
        return response.data;
      })
}