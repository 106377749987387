import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import arrow_down_white from '../../assets/icons/arrow-down-white.svg';
import arrow_down_pale_blue from '../../assets/icons/arrow-down-pale-blue.svg';

export default ({triggerContent, currentEmployment, canModify, dropdownMenuTourNames, isGuidedTourEnabled, onSwitchAccount}) => {

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const trigger = () => {
    setIsOpen(!isOpen);
  }

  const transitionTo = (route) => {
    navigate(route);
  }

  const resetTooltips = () => {
    setIsOpen(false);
  }

  const openSwitchAccount = () => {
    setIsOpen(false);
    onSwitchAccount();
  }

  const logout = (e) => {
    e.preventDefault();
    navigate("/login");
    localStorage.removeItem('user');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('currentOrganisation');
  }

  return (
    <div className='dropdown-menu user-account-dropdown-menu' tabIndex="9999" onBlur={() => setIsOpen(false)}>
      <div className='dropdown-menu-trigger' onClick={trigger}>
        <p>{triggerContent} <img alt="arrow-down-white" src={arrow_down_white} className="dropdown-arrow-white"/> <img alt="arrow-down-pale-blue" src={arrow_down_pale_blue} className="dropdown-arrow-pale-blue"/></p>
      </div>
    
      {isOpen && (
        <div className="dropdown-menu-list">
          <div className="dropdown-menu-list-item switch-account" onClick={openSwitchAccount}>
            Switch Account
          </div>
          <div className="dropdown-menu-list-item edit-profile" onClick={() => transitionTo('/edit/profile')} >
            Edit My Profile
          </div>
          <div className="dropdown-menu-list-item change-password" onClick={() => transitionTo('/change-password')} >
            Change Password
          </div>
          <div className="dropdown-menu-list-item terms" onClick={() => transitionTo('/terms')} >
            Terms and Conditions
          </div>
          {canModify.businessProfile(currentEmployment) && (
            <div stopName='updateBusiness' tourNames={dropdownMenuTourNames}>
              <div className="dropdown-menu-list-item edit-organisation" onClick={() => transitionTo('/edit/organisation')} >
                Update Business Details
              </div>
            </div>
          )}
          <div stopName='createBusiness' tourNames={dropdownMenuTourNames}>
            <div className="dropdown-menu-list-item new-organisation" onClick={() => transitionTo('/new/organisation')} >
              Create New Business
            </div>
          </div>
          <div className="dropdown-menu-list-item support" onClick={() => transitionTo('/support')} >
            Support
          </div>

          {isGuidedTourEnabled && (
            <div className="dropdown-menu-list-item reset-tooltips" onClick={resetTooltips} >
              Reset Tooltips
            </div>
          )}
          <div className='dropdown-menu-list-item logout' onClick={logout}>Log Out</div>
        </div>
      )}
    </div>
  )
}


