import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { australianStates, countries, newZealandStates } from '../../../utils/variables';
import { validateBusinessName, validateCountry, validateState } from '../../../utils/validators';
import AuthValidatedInput from '../AuthValidatedInput';
import FormValidatedSelect from '../FormValidatedSelect';
import { addOrganisation } from '../../../api/organisationApi';
import { store } from '../../../utils/store';

export default ({onAddOrganisation}) => {

  const { dispatch } = useContext(store);
  const navigate = useNavigate();

  // Name
  const [name, setName] = useState('');
  const [isValidName, setIsValidName] = useState(false);
  const [errorMessageName, setErrorMessageName] = useState('');
  const [showErrorMessageName, setShowErrorMessageName] = useState(false);

  // Country
  const [country, setCountry] = useState('');
  const [isValidCountry, setIsValidCountry] = useState(false);
  const [errorMessageCountry, setErrorMessageCountry] = useState('');
  const [showErrorMessageCountry, setShowErrorMessageCountry] = useState(false);

  // State
  const [state, setState] = useState('');
  const [isValidState, setIsValidState] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState('');
  const [showErrorMessageState, setShowErrorMessageState] = useState(false);

  const [didValidate, setDidValidate] = useState(false);
  const [hasConfirmedAuthorization, setHasConfirmedAuthorization] = useState(false);

  const save = (e) => {
    e.preventDefault();
    let nameValidation = false,
      countryValidation = false,
      stateValidation = false;
    if (checkName(name)) nameValidation = true;
    if (checkCountry(country)) countryValidation = true;
    if (checkState(state)) stateValidation = true;

    if (nameValidation &&
      countryValidation &&
      stateValidation) {
        const data = {
          data: {
            attributes: {
              "name": name,
              "country": country,
              "state": state,
              "wholesaler-authenticated": false,
              "cstatement": null
            },
            type: "organisations"
          }
        }
        addOrganisation(data).then((response) => {
          dispatch({ type: 'SET POPUP', payload: 'success' });
          dispatch({ type: 'SET POPUP MESSAGES', payload: ['Business Created'] });
          setTimeout(() => {
            dispatch({ type: 'SET POPUP', payload: '' })
          }, 3000)

          navigate('/sites');
          onAddOrganisation({
            label: response.data.attributes['name'],
            value: response.data.id
          });
        })
      }
  }

  const checkName = (input) => {
    const validateResult = validateBusinessName(input);
    if (validateResult.validated) {
      setShowErrorMessageName(false);
      setErrorMessageName('');
      setIsValidName(true);
    } else {
      setShowErrorMessageName(true);
      setErrorMessageName(validateResult.message);
      setIsValidName(false);
    }
    return validateResult.validated;
  }

  const checkCountry = (input) => {
    const validateResult = validateCountry(input);
    if (validateResult.validated) {
      setShowErrorMessageCountry(false);
      setErrorMessageCountry('');
      setIsValidCountry(true);
    } else {
      setShowErrorMessageCountry(true);
      setErrorMessageCountry(validateResult.message);
      setIsValidCountry(false);
    }
    return validateResult.validated;
  }

  const checkState = (input) => {
    const validateResult = validateState(input);
    if (validateResult.validated) {
      setShowErrorMessageState(false);
      setErrorMessageState('');
      setIsValidState(true);
    } else {
      setShowErrorMessageState(true);
      setErrorMessageState(validateResult.message);
      setIsValidState(false);
    }
    return validateResult.validated;
  }

  const changeName = (e) => {
    setName(e.target.value)
    checkName(e.target.value)
  }

  const changeCountry = (e) => {
    setCountry(e.target.value)
    checkCountry(e.target.value)
  }

  const changeState = (e) => {
    setState(e.target.value)
    checkState(e.target.value)
  }

  const changeConfirmatioin = () => {
    setHasConfirmedAuthorization(!hasConfirmedAuthorization);
  }

  return (
    <form id='organisation-new-form' className="form auth-form internal-auth-form" onSubmit={save}>

      <div className="row row-animated business-name">
        <AuthValidatedInput name='name' value={name} didValidate={didValidate} labelText='Business Name' isValid={isValidName} errorMessage={errorMessageName} showErrorMessage={showErrorMessageName} onChange={changeName} />
      </div>
    
      <div className="row row-animated country">
        <FormValidatedSelect name='country' options={countries} value={country} didValidate={didValidate} labelText='Country' isValid={isValidCountry} errorMessage={errorMessageCountry} showErrorMessage={showErrorMessageCountry} onChange={changeCountry}/>
      </div>
    
      <div className="row row-animated state">
        <FormValidatedSelect name='state' options={country === 'Australia' ? australianStates : country === 'New Zealand' ? newZealandStates : []} value={state} didValidate={didValidate} labelText='State' isValid={isValidState} errorMessage={errorMessageState} showErrorMessage={showErrorMessageState} onChange={changeState}/>
      </div>
    
      <div className="row business-confirmation">
        <input type="checkbox" id='businessConfirmation' checked={hasConfirmedAuthorization} onChange={changeConfirmatioin}/>
        <label htmlFor='businessConfirmation' className="business-confirmation-label">I confirm I am authorised to create this business account.</label>
      </div>
    
      <div className="actions">
        <button type="submit" className="button large green submit auth-submit" disabled={!hasConfirmedAuthorization} >Create Business</button>
      </div>
    
    </form>
  )
}