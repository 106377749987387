export const BASE_URL = "https://api.cleversparky.com/";

export const API_VERSION = "/api/v1";

export const SESSIONS = "/sessions";

export const USERS = "/users";

export const ME = "/me";

export const SITES = "/sites";

export const PLACES = "/places";

export const ALL_FITTINGS = "/allfittings"

export const FITTINGS = "/fittings"

export const DELETED_FITTINGS = "/deleted-fittings"

export const GROUPS = "/groups";

export const TEST_EVENTS = "/test-events";

export const FAULTS = "/faulty-fittings";

export const ORGANISATIONS = "/organisations";

export const SIGNED = "/signed_request";

export const SIGNED_XLSX = "/signed_requestxlsx";

export const SIGNED_SITE_DRAWINGS = "/signed_requestsitedrawingsurl";

export const XLSX = "/xlsx";

export const WHOLESALERS = "/wholesalers";

export const TESTS = "/tests";

export const MAINTENANCES = "/maintenances";

export const FITTING_LOOKUPS = "/fitting-lookups";

export const RELATIONSHIPS = "/relationships";

export const TEST_EVENT_GROUPS = "/test-event-groups";

export const MISSING_GROUPS = "/missing-groups";

export const TEST_EVENT_FITTINGS = "/test-event-fittings";

export const DUPLICATE = "/duplicate";

export const ARCHIVE = "/archive";

export const FITTINGS_UPLOADS = "/fittings-uploads";

export const TEMPLATES = "/templates";

export const REPORTS = "/reports";

export const EMPLOYMENTS = "/employments";

export const INVITES = "/invites";

export const ACCESS_REQUESTS = "/access-requests";

export const ANALYTICS = "/analytics";

export const SITE_PERMISSIONS = "/site-permissions";

export const RESTRICTED_SITES = "/restricted-sites";

export const PERMITTED_SITES = "/permitted-sites";

export const SITE_OWNER = "/site-owner";

export const DASHBOARD = "/dashboard";

export const SITE_DRAWINGS = "/sitedrawings";

export const INVITE_TRANSFER = "/invitetransfer";

export const ACCEPT_TRANSFER = "/accepttransfer";

export const RESET_PASSWORD = "/reset-password";

export const PERCENTAGE = "/dashperc";

export const FITTINGS_INSTALLED = "/dashfittings";

export const SUMMARY = "/dashsummary";

export const UPCOMING_TESTS = "/dashupcomingtests";

export const OVERDUE_SITES = "/dashoverduesites";

export const REINSTATE = "/reinstate";

export const EXPORT_SITES = '/exportsites';

export const DIFF_SITES = '/diffsite';