import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { authenticate } from '../api/authApi';
import { getInvite } from '../api/inviteApi';
import { getMe } from '../api/userApi';

import logo_clevertronics from "../assets/icons/logo-clevertronics.svg";
import logo_white from "../assets/icons/logo-white.svg";
import { store } from '../utils/store';
import { validateEmail, validatePassword } from '../utils/validators';
import LoginForm from "./components/forms/LoginForm";


const Login = ({onLoggedIn}) => {

  const { dispatch } = useContext(store);

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false);
  const [showErrorMessagePassword, setShowErrorMessagePassword] = useState(false);
  const [inviteObj, setInviteObj] = useState(null);

  const changeEmail = (e) => {
    setEmail(e.target.value)
    checkEmail(e.target.value)
  }

  const changePassword = (e) => {
    setPassword(e.target.value)
    checkPassword(e.target.value)
  }

  const login = () => {
    let emailValidation = false, passwordValidation = false;
    if (checkEmail(email)) {
      emailValidation = true;
    }
    if (checkPassword(password)) {
      passwordValidation = true;
    }
    if (emailValidation && passwordValidation) {

      authenticate(email, password).then(() => {

        getMe().then(response => {
          const user = response.data;
          dispatch({ type: 'SET USER', payload: user });
          localStorage.setItem('user', JSON.stringify(user));
          if (inviteObj) {
            navigate('/invite', {
              state: {
                user: user,
                inviteObj: inviteObj
              }
            });
            setInviteObj(null);
          } else {
            navigate('/sites', {
              state: {
                onLoggedIn: true
              }
            });
            onLoggedIn(user);
          }
        })

      }).catch(errors => {
        if (errors) {
          dispatch({ type: 'SET POPUP', payload: 'danger' });
          dispatch({ type: 'SET POPUP MESSAGES', payload: errors });
          setTimeout(() => {
            dispatch({ type: 'SET POPUP', payload: '' })
          }, 3000)
        }
      });
      
    }
  }

  const checkEmail = (input) => {
    const validateResult = validateEmail(input);
    if (validateResult.validated) {
      setShowErrorMessageEmail(false);
      setErrorMessageEmail('');
      setIsValidEmail(true);
      return true;
    } else {
      setShowErrorMessageEmail(true);
      setErrorMessageEmail(validateResult.message);
      setIsValidEmail(false);
      return false;
    }
  }

  const checkPassword = (input) => {
    const validateResult = validatePassword(input);
    if (validateResult.validated) {
      setShowErrorMessagePassword(false);
      setErrorMessagePassword('');
      setIsValidPassword(true);
      return true;
    } else {
      setShowErrorMessagePassword(true);
      setErrorMessagePassword(validateResult.message);
      setIsValidPassword(false);
      return false;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (window.sessionStorage.branch_session) {
        let branchData = JSON.parse(window.sessionStorage.branch_session).data;
        let inviteObj = JSON.parse(branchData).invite;
        if (inviteObj) {
          setInviteObj(inviteObj);
          const sessionToken = localStorage.getItem('sessionToken');
          const sessionId = localStorage.getItem('sessionId');
          if (sessionToken && sessionId) {
            getMe().then(response => {
              const user = response.data;
              dispatch({ type: 'SET USER', payload: user });
              localStorage.setItem('user', JSON.stringify(user));
              navigate('/invite', {
                state: {
                  user: user,
                  inviteObj: inviteObj
                }
              });
              setInviteObj(null);
              window.sessionStorage.clear();
            })
          }
        } 
      } else {
        let localUser = localStorage.getItem('user');
        if (localUser) {
          navigate('/sites');
        }
      }
    }, 2000);
  }, [])
 
  return (
    <div id="login" className="auth-page">
      <div className="content">
        <img src={logo_white} className="logo-large" />
        <h2 className="form-heading">Log In</h2>

        <LoginForm 
          email={email} 
          password={password} 
          isValidEmail={isValidEmail}
          isValidPassword={isValidPassword}
          errorMessageEmail={errorMessageEmail}
          errorMessagePassword={errorMessagePassword}
          showErrorMessageEmail={showErrorMessageEmail}
          showErrorMessagePassword={showErrorMessagePassword}
          onChangeEmail={changeEmail} 
          onChangePassword={changePassword}
          onSubmit={login}
        />

        <a href='/signup/terms' className='signup'>Sign Up</a>

        <a href="http://www.clevertronics.com.au" target="_blank">
          <img src={logo_clevertronics} className="" />
        </a>
        <br/>
        Version: 88
      </div>
    </div>
  );
}

export default Login;
