import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import icon_back from '../../assets/images/icon-back.svg';
import test_event_grey from '../../assets/icons/test-event-grey.svg';
import TestEventFittingDropdownMenu from './TestEventFittingDropdownMenu';
import test_out_of_range_flag from '../../assets/icons/test-out-of-range-flag.svg';
import illus_fittings from '../../assets/images/illus-fittings.svg';
import icon_add_white from '../../assets/images/icon-add-white.svg';
import QuickTestModal from './QuickTestModal';
import { store } from '../../utils/store';

export default ({
  displayFittings, 
  testEvent, 
  groupId,
  groupName,
  notCollectedFittings,
  collectedFittings,
  testEventGroups, 
  onCollectedFilter, 
  missingGroups,
  isNotCollected,
  isCollected, 
  onSort,
  onBack,
  onShowAddFittingModal,
  onShowTestEventFittingDetails,
  onEditDateRangeModal,
  onDuplicateTestEventModal,
  onArchiveConfirmationModal,
  onQuickTestDefaultsModal,
  onUpdateFittings
}) => {

  const { dispatch } = useContext(store);

  const [isSelectingTestEventFittings, setIsSelectingTestEventFittings] = useState(false);
  const [fittings, setFittings] = useState([]);
  const [showQuickTestPassModal, setShowQuickTestPassModal] = useState(false);
  const [showQuickTestFailModal, setShowQuickTestFailModal] = useState(false);
  const [noFittingsSelected, setNoFittingsSelected] = useState(true);

  const showRemoveGroupsModal = () => {
    
  }

  const showAddFittingModal = () => {

  }

  const SelectAll = () => {
    let newFittings = _.cloneDeep(fittings);
    newFittings.forEach(ft => {
      ft.checked = true;
    })
    setFittings(newFittings);
  }

  const cancelSelectTestEventFittings = () => {
    setIsSelectingTestEventFittings(false);
    setShowQuickTestFailModal(false);
    setShowQuickTestPassModal(false);
    setFittings(displayFittings);
  }

  const batchTestResults = () => {
    setIsSelectingTestEventFittings(true);
  }

  const showTestEventFitting = () => {

  }

  const selectFitting = (fitting) => {
    let newFittings = _.cloneDeep(fittings);
    newFittings.forEach(ft => {
      if (ft.id === fitting.id) {
        if (fitting.checked) {
          ft.checked = false;
        } else {
          ft.checked = true;
        }
      }
    })
    setFittings(newFittings);
  }

  const submitForm = () => {
    setIsSelectingTestEventFittings(false);
    setShowQuickTestFailModal(false);
    setShowQuickTestPassModal(false);
    dispatch({ type: 'SET POPUP', payload: 'success' });
    dispatch({ type: 'SET POPUP MESSAGES', payload: ['Batch Test Results Added Successfully'] });
    setTimeout(() => {
      dispatch({ type: 'SET POPUP', payload: '' })
    }, 3000)
    onUpdateFittings(groupId);
  }

  useEffect(() => {
    setFittings(displayFittings);
  }, [displayFittings])

  useEffect(() => {
    if (fittings) {
      let noSelected = true;
      fittings.forEach(fitting => {
        if (fitting.checked) {
          noSelected = false;
        }
      })
      setNoFittingsSelected(noSelected);
    }
  }, [fittings])

  return (
    <div id="test-event-fitting-table" className="table">
      <table>
        <thead>
          <tr>
            <th className="table-row">
              <div className="test-event-fitting-table-head">
                <div className="inner">
                  <div className="back">
                    <img src={icon_back} className='icon-back' alt="Back icon" onClick={onBack}/>
                    <img src={test_event_grey} className='icon-test-event icon-test-event-grey' alt="Test event icon grey"/>
                    {testEvent.attributes['start-date']} to {testEvent.attributes['end-date']}
                  </div>
                </div>
              </div>
              <div className="test-event-fitting-table-head">
                <div className="inner">
                  <div className="test-event-group" style={{display: "inline"}}>
                    {fittings && !groupName && (
                      <TestEventFittingDropdownMenu 
                        testEventGroups={testEventGroups}
                        missingGroups={missingGroups}
                        onAddGroupsModal={onShowAddFittingModal}
                        onRemoveGroupsModal={showRemoveGroupsModal}
                        onEditDateRangeModal={onEditDateRangeModal}
                        onDuplicateTestEventModal={onDuplicateTestEventModal}
                        onArchiveConfirmationModal={onArchiveConfirmationModal}
                        onQuickTestDefaultsModal={onQuickTestDefaultsModal}
                      />
                    )}
    
                    {groupName}
                    <button style={{paddingLeft: "10px"}} onClick={onSort}>Sort List</button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
    
        <tr>
          <td className="table-row filter-row">
            <div className={`filter-column ${isNotCollected && 'active'}`} onClick={() => onCollectedFilter(false)} >Not Collected ({notCollectedFittings.length})</div>
            <div className={`filter-column ${isCollected && 'active'}`} onClick={() => onCollectedFilter(true)} >Collected ({collectedFittings.length})</div>
          </td>
        </tr>

        <tbody className="multiselect-checkboxes">
          {fittings && fittings.map(fitting => (
            <tr key={fittings.indexOf(fitting)} onClick={isSelectingTestEventFittings ? showTestEventFitting : () => onShowTestEventFittingDetails(fitting)}>
              <td className={`table-row ${isSelectingTestEventFittings && 'selecting-test-event-fittings'}`} >
                <div className='column device-reference'>
                  {isSelectingTestEventFittings && (
                    <>
                      <input type="checkbox" id={fitting.id} checked={fitting.checked} onChange={() => selectFitting(fitting)}/>
                      <label htmlFor={fitting.id}></label>
                    </>
                  )}
                  <div className="truncated">
                    {fitting.attributes['device-reference']}
                  </div>
                </div>
                <div className='column row-indicator'>
                  {fitting.testOutOfRange && (
                    <img src={test_out_of_range_flag} alt="Test out of range indicator"/>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      
      </table>
        
      {showQuickTestPassModal && (
        <QuickTestModal testEvent={testEvent} fittings={fittings} isQuickTestPassForm={true} headingText='Mark Selected Fittings as Pass' onClose={() => setShowQuickTestPassModal(false)} onSubmitForm={submitForm}/>
      )}

      {showQuickTestFailModal && (
        <QuickTestModal testEvent={testEvent} fittings={fittings} isQuickTestPassForm={false} headingText='Mark Selected Fittings as Fail' onClose={() => setShowQuickTestFailModal(false)} onSubmitForm={submitForm}/>
      )}
      
      {fittings && fittings.length === 0 && (
          <div className="empty-table">
            <div className="empty-image">
              <img src={illus_fittings} alt="Blue Row Icon"/>
            </div>
            {!testEvent.attributes['archived'] && (
              <>
                <div className="empty-message">
                  {testEventGroups.length === 0 ? 'To start populating this logbook group, click on the add fitting button below.' : 'Add a fitting using the button below.'}
                </div>
                <div className="empty-actions">
                  <button  className='action button icon green' onClick={onShowAddFittingModal} >
                    <img className="icon" src={icon_add_white} alt="Add Icon"/>
                    Add Fitting
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {fittings && fittings.length !== 0 && (
          <>
          <div className="table-actions">
            <div className="left">
            </div>
      
            {!testEvent.attributes['archived'] && (
              <div className="right">
                {isSelectingTestEventFittings ? (
                  <>
                    <div  style={{display: "inline-block"}}><button className='button small text-blue cancel' onClick={SelectAll} >Select All</button></div>
                    <button className='button small text-blue cancel' onClick={cancelSelectTestEventFittings} >Cancel</button>
                    <button className='button standard green batch-pass' disabled={noFittingsSelected} onClick={() => setShowQuickTestPassModal(true)}>Pass</button>
                    <button className='button standard red batch-fail' disabled={noFittingsSelected} onClick={() => setShowQuickTestFailModal(true)}>Fail</button>
                  </>
                ) : (
                  <>
                    {isNotCollected && (
                      <button className='button small text-blue batch-test-results' onClick={batchTestResults}>Quick Collect Results</button>
                    )}
                    <button  className='action button icon green' onClick={onShowAddFittingModal}>
                      <img className="icon" src={icon_add_white} alt="Add Icon"/>
                      Add Fitting
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
          </>
        )}
    </div>
  )
}
