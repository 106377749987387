import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

export default ({imageSrc, title, text, textStyleClass, isLastSlide}) => {

  const navigate = useNavigate();

  const goToSites = () => {
    navigate('/sites');
  }

  return (
    <div className='onboarding-slide'>
      <img src={imageSrc} alt="Onboarding image"/>
      <h2>{title}</h2>
      <p className={`onboarding-text ${textStyleClass}`}>
        {text}
      </p>
      {isLastSlide ? (
        <button className='button standard blue get-started' type="button" onClick={goToSites} >Get Started</button>
      ) : (
        <button className="button white skip" onClick={goToSites}>Skip</button>
      )}
    </div>
  )
}


