import React, { useState, useEffect } from 'react';
import test_event_grey from '../../assets/icons/test-event-grey.svg';
import test_event_blue from '../../assets/icons/test-event-blue.svg';
import icon_arrow_right_grey from '../../assets/images/icon-arrow-right-grey.svg';
import icon_arrow_right_navy from '../../assets/images/icon-arrow-right-navy.svg';
import icon_add_white from '../../assets/images/icon-add-white.svg';
import illus_test_events from '../../assets/images/illus-test-events.svg'
import delete_icon from '../../assets/icons/delete.svg';
import LoadMoreButton from './LoadMoreButton';

export default ({
  testEvents,
  testEventTotal,
  selectedTestEvents,
  onOpenAddTestEventModal,
  isDeletingTestEvents,
  onLoadMore,
  onSelectTestEvent,
  onDeleteTestEvents,
  onShowTestEvent,
  onToggleDeleteTestEvents
}) => {

  return (
    <div id="test-event-table" className="table">
      <table>
        <thead>
          <tr>
            <th className="column">
              <div className="inner">
                Label
              </div>
            </th>
            <th className="startDate column">
              <div className="inner">
                Start Date
              </div>
            </th>
            <th className="endDate column">
              <div className="inner">
                End Date
              </div>
            </th>
            <th className="row-indicator"></th>
          </tr>
        </thead>

        <tbody className="multiselect-checkboxes">
          {testEvents.map(testEvent => (
            <tr key={testEvents.indexOf(testEvent)} className="row" onClick={isDeletingTestEvents ? null : (() => onShowTestEvent(testEvent))} >
              <td className='column endDate'>{testEvent.attributes['label']}</td>
              <td className={`column startDate ${isDeletingTestEvents && 'checkbox'}`}>
                {isDeletingTestEvents ? (
                  <>
                    <input type="checkbox" id={testEvent.id} checked={testEvent.checked} onClick={() => onSelectTestEvent(testEvent)} />
                    <label htmlFor={testEvent.id}></label>
                  </>
                ) : (
                  <>
                    <img src={test_event_grey} className='icon-test-event icon-test-event-grey' alt="Test event icon grey"/>
                    <img src={test_event_blue} className='icon-test-event icon-test-event-blue' alt="Test event icon blue"/>
                  </>
                )}
                <div className="truncated">
                  {testEvent.attributes['start-date']}
                </div>
              </td>
              <td className='column endDate'>{testEvent.attributes['end-date']}</td>
              <td className="column row-indicator">
                {testEvent.attributes['archived'] ? (
                  <div className="test-event-archived">
                    archived
                  </div>
                ) : (
                  <>
                    <div className="arrow-right-grey">
                      <img src={icon_arrow_right_grey} alt="Row Icon"/>
                    </div>
                    <div className="arrow-right-grey-blue">
                      <img src={icon_arrow_right_navy} alt="Blue Row Icon"/>
                    </div>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    
      {testEvents.length === 0 ? (
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_test_events} alt="Empty Table Image"/>
          </div>
          <div className="empty-message">
            Click on the button below to add your first logbook.
          </div>
          <div className="empty-actions">
            <button className="action button icon green" onClick={onOpenAddTestEventModal}>
              <img className="icon" src={icon_add_white} alt="Add Icon"/>
              Add Logbook
            </button>
          </div>
        </div>
      ) : (
        <div className="table-actions">
          <div className="left">
            <LoadMoreButton 
              canLoad={testEvents.length < testEventTotal} 
              loadText='Load More Logbooks' 
              numberOfItemsDisplayedText={`(Showing ${testEvents.length} of ${testEventTotal})`} 
              onLoadMore={onLoadMore}
            />
          </div>
    
          <div className="right">
            {isDeletingTestEvents ? (
              <>
              <button className="button blue standard cancel" onClick={onToggleDeleteTestEvents}>
                Cancel
              </button>
              <button className="button red delete-multiple-test-events" disabled={selectedTestEvents.length === 0} onClick={onDeleteTestEvents} >
                Delete Logbooks
              </button>
              </>
            ) : (
              <>
                <div className="button small text-red delete-test-events" onClick={onToggleDeleteTestEvents} >
                  <img className='icon' src={delete_icon} alt="Delete Icon"/>
                  Delete Logbooks
                </div>
                <button className="action button icon green" onClick={onOpenAddTestEventModal} >
                  <img className="icon" src={icon_add_white} alt="Add Icon"/>
                  Add Logbook
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}